import React, { Component } from 'react'

import startImage from "../../images/start.png";
import endImage from "../../images/end.png";

let map;
let allMarker = [];
let line;

export default class GoogleMap extends Component {
  state = {
    googleMapData: []
  }
  componentWillMount(){
    this.setState({
      googleMapData: this.props.googleMapData.GPS
    })
  }
  componentDidMount() {
    const data = this.state.googleMapData
    this.initMap();
    if (data.length > 0){
      this.createLine(data);
    }
  }

  render() {
    return (
      <div id="googleMap" ref="map" style={{ width: '100%', height: 'calc(100vh - 205px)' }}></div>
    )
  }
  initMap = () => {
    const mapOptions = {
      center: { lat: 12.642036303257045, lng: -8.00376439745919 },
      zoom: 12,
    }
    map = new window.google.maps.Map(document.getElementById("googleMap"), mapOptions);
  }

  createLine = (data) => {

    //set center
    map.setCenter(new window.google.maps.LatLng(data[0].Lat, data[0].Lng));

    let latlngArr = [];

    for (var i = 0; i < data.length; i++) {
      latlngArr[i] = new window.google.maps.LatLng(data[i].Lat, data[i].Lng);
    }

    //draw line
    line = new window.google.maps.Polyline({
      path: latlngArr,
      strokeColor: "blue",
      strokeOpacity: .8,
      strokeWeight: 10,
      map: map,
    });

    //middle
    const middle = {
      path: window.google.maps.SymbolPath.CIRCLE,
      fillColor: '#fff',//符号填充的颜色
      fillOpacity: 1,//填充的透明度,默认0
      strokeColor: 'blue',//符号边框的颜色
      scale: 6,//缩放大小的数量
      strokeWeight: 1,//符号轮廓的粗细。默认值为scale符号的。
    };
    //start
    const start = {
      url: startImage,
      //size: new google.maps.Size(11, 11),//大小
      //origin: new google.maps.Point(0, 0),
      //scaledSize: new google.maps.Size(0, 0),
      // anchor: new window.google.maps.Point(0, 0)//偏移量
    }
    //end
    const end = {
      url: endImage,
      anchor: new window.google.maps.Point(25, 40)
    }

    //画出线上的点
    for (let i = 0; i < data.length; i++) {
      let image;
      if (i === 0) {
        image = start //起点
      } else if (i === data.length - 1) {
        image = end //终点
      } else {
        image = middle //中间点
      }
      let marker = new window.google.maps.Marker({
        position: { lat: data[i].Lat, lng: data[i].Lng },
        map: map,
        icon: image
      });
      clickMarker(marker, data[i])//点的点击事件，直接写在循环里的话只显示最后一个的点信息，所以要写在外面
      allMarker.push(marker)
    }

    var infowindow = new window.google.maps.InfoWindow();//信息窗口
    //每个marker的信息窗口
    function clickMarker(marker, data) {
      window.google.maps.event.addListener(marker, 'click', function () {
        var content = "<div>" +
          "<p>经纬度：" + (data.Lng || '') + "，" + (data.Lat || '') + "</p>" +
          "<p>Time：" + (data.Time || '') + "</p><div>"
        infowindow.setContent(content)
        infowindow.open(map, marker);//打开信息窗口
      });
    }
  }
}
