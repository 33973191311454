import React from 'react';
import { withRouter, Link } from 'react-router-dom';

import { Table, message } from 'antd';

import './admin.css';

import { httpGet } from "http/index.js";

class Admin extends React.Component {
  state = {
    dataSource: []
  }
  
  componentDidMount() {
    this.getVehicleList();

    this.intervalID = setInterval(this.getVehicleList, 60*1000);
  }

  componentWillUnmount() {
    this.intervalID && clearInterval(this.intervalID);
  }

  render() {
    const columns = [
      {
        title: 'No. d\'immatriculation du véhicule',
        dataIndex: 'plate',
        key: 'plate',
      },
      {
        title: 'No. de châssis',
        dataIndex: 'chasis',
        key: 'chasis',
      },
      {
        title: 'Type de véhicule',
        dataIndex: 'vehicle',
        key: 'vehicle',
      },
      {
        title: 'Propriétaire',
        dataIndex: 'owner',
        key: 'owner',
      },
      {
        title: 'No. de carte d\'identité',
        dataIndex: 'id_card',
        key: 'id_card',
      },
      {
        title: 'Statut',
        dataIndex: 'status',
        key: 'status',
      },
    
      {
        title: 'Infraction',
        key: 'offence',
        dataIndex: 'offence',
      },
      {
        title: 'Montant de l\'amende',
        key: 'fine',
        dataIndex: 'fine',
      },

      {
        title: 'Action',
        align: 'center',
        render: record => <span className="wrappedStatus"> <Link to={"/map/" + record.plate}>Map</Link></span>
      },
    ];
    return (
      <div className='home' key="home">
        <Table rowKey={record => record.id} dataSource={this.state.dataSource} columns={columns} pagination={{ pageSize: 20 }} />
      </div>
    );
  };

  getVehicleList = () => {
    httpGet("/api/v1/instance/get_vehicle_list")
      .then(res => {
        return res.json();
      })
      .then(data => {
        if (data.code === 0) {
          this.setState({
            dataSource: data.data,
          })
        } else {
          message.error('Interface error:get_vehicle_list');
        }
      })
      .catch(err => {
        message.error('Interface error:get_vehicle_list');
      }
      )
  }

};

export default withRouter(Admin);