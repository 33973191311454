import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Layout, Menu, Icon, Avatar, Dropdown, message } from 'antd';
import { httpPost } from "http/index.js";

import 'style/Outline.scss';
import 'style/common.css';

const { Header, Content, Footer, Sider } = Layout;

class LeftSider extends Component {
  state = {
    collapsed: false,
    is_admin: sessionStorage.getItem("role") === "admin" ? true : false
  };

  onCollapse = collapsed => {
    console.log(collapsed);
    this.setState({ collapsed });
  };

  render() {
    const isAdminLink = this.state.is_admin
      ?
      <Menu theme="dark" defaultSelectedKeys={['/']} mode="inline">
        <Menu.Item key="Admin">
          <Link to="/admin">
            <Icon type="home" />
            <span>Admin</span>
          </Link>
        </Menu.Item>
      </Menu>
      :
      <Menu theme="dark" defaultSelectedKeys={['/']} mode="inline">
        <Menu.Item key="Home">
          <Link to="/home">
            <Icon type="home" />
            <span>La maison</span>
          </Link>
        </Menu.Item>
      </Menu>
    return (
      <Sider collapsible collapsed={this.state.collapsed} onCollapse={this.onCollapse}>
        <div className="logo">
          <h1 className={this.state.collapsed ? 'small' : 'medimu'}>véhicule</h1>
        </div>

        {isAdminLink}
      </Sider>);
  }
}

class GeneralHeader extends Component {

  logout = () => {
    httpPost("/auth/logout")
      .then(res => {
        return res.json();
      })
      .then(data => {
        console.log(data);
        if (data.code === 0) {
          sessionStorage.setItem("isLogin", "0");
          window.location.reload()
        }
      })
      .catch(err => {
        message.error(err);
      })
  }

  render() {
    const menu = (
      <Menu onClick={this.logout}>
        <Menu.Item key="1">
          <Icon type="user" />
          Sortie
        </Menu.Item>
      </Menu>
    );

    return (
      <Header >
        <div className='user' >
          <div id="components-dropdown-demo-dropdown-button">
            <Dropdown overlay={menu} placement="bottomLeft" >
              <Avatar className='user_header' size={48}  >
                {sessionStorage.getItem("username") ? sessionStorage.getItem("username")[0].toUpperCase() : ""}
              </Avatar>
            </Dropdown>
          </div>
        </div>
      </Header>
    );
  }
}

class Outline extends Component {
  render() {
    return (
      <div className="customize">
        <Layout style={{ minHeight: '100vh' }}>
          <LeftSider></LeftSider>
          <Layout>
            <GeneralHeader />

            <Content className="content">
              {this.props.children}
            </Content>

            <Footer>
              Produk ©2021 Created by Nextan
            </Footer>
          </Layout>
        </Layout>
      </div>
    );
  }
}

export default Outline