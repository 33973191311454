import React, { Component } from 'react'
import AuthRouter from 'components/Router/AuthRouter.js'
import Outline from 'components/Outline'
import Home from "components/Home"
import Admin from "components/Admin"
import Map from "components/Map"
import { Switch } from 'react-router'
import NotFound from "components/404"

class SubRouter extends Component {
  state = {
    is_admin: sessionStorage.getItem("role") === "admin" ? true : false
  };

  render() {
    const isAdminLink = this.state.is_admin
      ?
      <Switch>
        <AuthRouter exact path='/' component={Admin}></AuthRouter>
        <AuthRouter path='/admin' component={Admin}></AuthRouter>
        <AuthRouter path='/map/:plate' component={Map}></AuthRouter>

        <AuthRouter path="*" component={NotFound}></AuthRouter>
      </Switch>
      :
      <Switch>
        <AuthRouter exact path='/' component={Home}></AuthRouter>
        <AuthRouter path='/home' component={Home}></AuthRouter>
        <AuthRouter path='/map/:plate' component={Map}></AuthRouter>

        <AuthRouter path="*" component={NotFound}></AuthRouter>
      </Switch>

    return (
      <Outline>
        {isAdminLink}
      </Outline>
    );
  }
}


export default SubRouter