import React, { Component } from 'react';

import {  message } from 'antd';

import { httpGet } from "http/index.js";
// import GoogleMap from './googleMap';
import "./map.css";

import startImage from "../../images/start.png";
import endImage from "../../images/end2.png";

let map;
let markers = [];

class Map extends Component {
  state = {
    googleMapData: []
  }
  

  componentDidMount() {
    if(!window.google){message.error('Internet error:google map');return};

    this.initMap();
    this.getGpsHistory();

    this.intervalID = setInterval(this.getGpsHistory, 10*1000);
  }

  componentWillUnmount() {
    this.intervalID && clearInterval(this.intervalID);


  }

  render() {
    return (
      <div className="allmap">
        <div className="wrappedSearch">
          <span style={{ marginLeft: 10, fontSize: 20 }}>All GPS Location</span>
          <img src={require('../../images/nextan_logo.png')} style={{width: 140, height: 'auto', float: 'right'}} alt=""/>
        </div>

        <div className="wrappedMap">
          {/* <GoogleMap googleMapData={this.state.googleMapData} key={this.state.googleMapData.random} /> */}
          <div id="allGoogleMap2" ref="map" style={{ width: '100%', height: 'calc(100vh)' }}></div>
        </div>
      </div>
    )
  }


  deleteMarkers = () => {
    for (let i = 0; i < markers.length; i++) {
      markers[i].setMap(null);
    }
    markers = [];
  }
  
  getGpsHistory = () => {

    httpGet("/api/v1/instance/get_vehicles_current_gps")
      .then(res => {
        return res.json();
      })
      .then(data => {
        if (data.code === 0) {

          this.setState({
            googleMapData: data.data,
          })
        } else {
          message.error('Interface error: get_gps_history2');
        }
      }).then(() => {
        const data = this.state.googleMapData
        // this.initMap();  
        
        this.deleteMarkers()
        
        if (data.length > 0){
          this.createLine(data);
        }
      })
      .catch(err => {
        console.log(err)
        message.error('Interface error: get_gps_history1');
      }
      )
  }

  initMap = () => {
    let zoom = parseInt(sessionStorage.getItem("zoom"))
    let lat = parseFloat(sessionStorage.getItem("centerLat"));
    let lng = parseFloat(sessionStorage.getItem("centerLng"));
    
    zoom = zoom ? zoom : 14
    lat = lat ? lat : 12.642036303257045
    lng = lng ? lng : -8.00376439745919

    const mapOptions = {
      center: { lat: lat, lng:  lng},
      zoom:  zoom,
      gestureHandling: "greedy"
    }
    map = new window.google.maps.Map(document.getElementById("allGoogleMap2"), mapOptions);

    window.google.maps.event.addListener(map,"zoom_changed", function() { 
      //Set local storage variables. 
      let mapCenter = map.getCenter(); 
      sessionStorage.setItem("centerLat", mapCenter.lat());
      sessionStorage.setItem("centerLng", mapCenter.lng());
      sessionStorage.setItem("zoom", map.getZoom());
    }) 

    window.google.maps.event.addListener(map,"center_changed", function() { 
      //Set local storage variables. 
      let mapCenter = map.getCenter(); 
      sessionStorage.setItem("centerLat", mapCenter.lat());
      sessionStorage.setItem("centerLng", mapCenter.lng());
      sessionStorage.setItem("zoom", map.getZoom());
    }) 

  }

  createLine = (data) => {
    let zoom = parseInt(sessionStorage.getItem("zoom"))
    let lat = parseFloat(sessionStorage.getItem("centerLat"));
    let lng = parseFloat(sessionStorage.getItem("centerLng"));
    
    zoom = zoom ? zoom : 14
    lat = lat ? lat : data[0].Lat
    lng = lng ? lng : data[0].Lng

    // set center
    map.setCenter(new window.google.maps.LatLng(lat, lng));
   
    // set zoom
    map.setZoom(zoom)

    // map.clearOverlays();
    
    // start
    const start = {
      url: startImage
    }

    // end
    const end = {
      url: endImage
    }

    //画出线上的点
    for (let i = 0; i < data.length; i++) {
      let image;
      // image = i % 2 === 0 ? start : end
      image = end
      let marker = new window.google.maps.Marker({
        position: { lat: data[i].Lat, lng: data[i].Lng },
        map: map,
        icon: image
      });

      let infowindow = new window.google.maps.InfoWindow();//信息窗口
      let content =  (data[i].Plate || '') 
      infowindow.setContent(content)
      infowindow.open(map, marker);//打开信息窗口

      markers.push(marker);
    }
  }
}
export default Map;