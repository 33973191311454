import React, { Component } from 'react';

import {  message } from 'antd';

import { httpGet } from "http/index.js";
// import GoogleMap from './googleMap';

import startImage from "../../images/start.png";
import endImage from "../../images/end2.png";
import "./map.css";

let map = {};

class Map extends Component {
  state = {
    googleMapData: [],
    all_marker: []
  }
  
  componentDidMount(){
    const that = this;
    if(!window.google){message.error('Internet error:google map');return};
    
    let zoom = parseInt(sessionStorage.getItem("zoom"))
    let lat = parseFloat(sessionStorage.getItem("centerLat"));
    let lng = parseFloat(sessionStorage.getItem("centerLng"));

    //初始化地图
  	let mapProp = {
        center:new window.google.maps.LatLng(lat? lat : 12.642036303257045,lng ? lng: -8.00376439745919),
        zoom: zoom ? zoom: 14,
        disableDefaultUI:true,
        // mapTypeId: window.google.maps.MapTypeId.HYBRID
    };
    map = new window.google.maps.Map(this.refs.map, mapProp);

    window.google.maps.event.addListener(map,"zoom_changed", function() { 
      //Set local storage variables. 
      let mapCenter = map.getCenter(); 
      sessionStorage.setItem("centerLat", mapCenter.lat());
      sessionStorage.setItem("centerLng", mapCenter.lng());
      sessionStorage.setItem("zoom", map.getZoom());
    }) 

    window.google.maps.event.addListener(map,"center_changed", function() { 
      //Set local storage variables. 
      let mapCenter = map.getCenter(); 
      sessionStorage.setItem("centerLat", mapCenter.lat());
      sessionStorage.setItem("centerLng", mapCenter.lng());
      sessionStorage.setItem("zoom", map.getZoom());
    }) 

    this.getWorkersList();
    this.timer = setInterval(function(){that.getWorkersList()},10 * 1000);
  }

  componentWillUnmount(){
      clearInterval(this.timer);
  }
  clearMaker = () => {
      this.state.all_marker.forEach((item, index)=>{
          item.setMap(null);
      })
  }
  getWorkersList = () => {
      httpGet("/api/v1/instance/get_vehicles_current_gps")
      .then(res => {
          return res.json();
      })
      .then(data => {
          this.renderMarker(data.data);
      })  
      .catch(err => {
          message.error('Interface error:get_vehicle_list');
      })
  }
  
  renderMarker = (data) => {
      //地图标记
      this.clearMaker();
      const all_marker = [];
      let zoom = parseInt(sessionStorage.getItem("zoom"))
      let lat = parseFloat(sessionStorage.getItem("centerLat"));
      let lng = parseFloat(sessionStorage.getItem("centerLng"));

      map.setZoom(zoom ? zoom : 14)
      map.setCenter(new window.google.maps.LatLng(lat ? lat : data[0].Lat, lng ?  lng :data[0].Lng));

      data.forEach((value, index, arr)=>{
          let marker = new window.google.maps.Marker({
            position: new window.google.maps.LatLng(value.Lat, value.Lng),
            icon: {
              labelOrigin: new window.google.maps.Point(25, -5),
              url: endImage,
            },
            title: value.Plate,
            label: {
              color: '#000',
              fontWeight: 'bold',
              text: value.Plate
            }
          })
          marker.setMap(map);

          all_marker.push(marker);
          this.setState({
              all_marker
          });
      })    
  }
  render() {
    return (
      <div className="allmap3">
        <div className="wrappedSearch">
          <span style={{ marginLeft: 10, fontSize: 20 }}>All GPS Location</span>
          <img src={require('../../images/nextan_logo.png')} style={{float: 'right', width: 140, height: 'auto', marginRight: 20}} alt=""/>
        </div>
        <div className="wrappedMap">
          <div id="googleMap" ref="map"  style={{ width: '100%', height: 'calc(100vh - 70px)' }}></div>
        </div>
      </div>
    )
  }
}

export default Map