import React, { Component } from 'react';
import { Form, Icon, Input, Button, message } from 'antd';
import './login.css';
import { httpPost } from "http/index.js";
const FormItem = Form.Item;

class NormalLoginForm extends Component {
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, fieldsValue) => {
      if (err) return;
      httpPost("/auth/login", JSON.stringify(fieldsValue))
        .then(res => {
          return res.json();
        })
        .then(data => {
          if (data.status === "ok") {
            sessionStorage.setItem("isLogin", "1");
            sessionStorage.setItem("username", data.name);
            sessionStorage.setItem("confirm", data.confirm);
            sessionStorage.setItem("role", data.role)
            sessionStorage.setItem("token", data.token)
            this.props.history.push('/');
          } else {
            message.error(data.msg);
          }
        })
        .catch(err => {
          message.error('Interface error:login');
        })
    });
  };



  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="login">
        <div className="login-form">
          <div className="login-logo">
            <div className="login-name">Centre de <br/>commandement central</div>
          </div>
          <Form onSubmit={this.handleSubmit} style={{ maxWidth: '300px' }}>
            <FormItem>
              {getFieldDecorator('username', {
                rules: [{ required: true, message: "Veuillez saisir un nom d'utilisateur." }],
              })(
                <Input prefix={<Icon type="user" style={{ fontSize: 13 }} />} placeholder="Nom d'utilisateur" />
              )}
            </FormItem>
            <FormItem>
              {getFieldDecorator('password', {
                rules: [{ required: true, message: "Veuillez saisir le mot de passe." }],
              })(
                <Input prefix={<Icon type="lock" style={{ fontSize: 13 }} />} type="password" placeholder="Mot de passe" />
              )}
            </FormItem>
            <FormItem style={{ marginBottom: '0' }}>
              <Button type="primary" htmlType="submit" className="login-form-button" style={{ width: '100%' }}>
                Connexion
              </Button>
            </FormItem>
          </Form>
        </div>
      </div>
    );
  }
}

const Login = Form.create()(NormalLoginForm);
export default Login;