import React, { Component } from 'react';

import { Button, DatePicker, message } from 'antd';
import moment from 'moment';

import { httpGet } from "http/index.js";
import GoogleMap from './googleMap';
import "./map.css";

const dateFormat = 'YYYY-MM-DD HH:mm:ss';
const zeroFormat = 'YYYY-MM-DD 00:00:00';
const timeFormat = 'HH:mm';

class Map extends Component {
  state = {
    date: "",
    googleMapData: {
      GPS: [],
      random: Math.random()
    },
    plate: ""
  }

  componentWillMount() {
    const match = this.props.match;

    // const date = moment("2021-07-16 00:00:00").utc().format(dateFormat);
    const date = moment().format(dateFormat);

    this.setState({
      plate: match.params.plate,
      date: date,
    })
  }
  componentDidMount() {
    this.getGpsHistory();

    this.intervalID = setInterval(this.getGpsHistory, 5*1000);
  }

  componentWillUnmount() {
    this.intervalID && clearInterval(this.intervalID);
  }

  render() {
    return (
      <div className="allmap">
        <div className="wrappedSearch">
          {/* <span style={{ marginLeft: 10, fontSize: 20 }}>{this.state.googleMapData.Plate} :</span> */}

          <span style={{ marginLeft: 10, fontSize: 20 }}>All GPS Location</span>
          <img src={require('../../images/nextan_logo.png')} style={{width: 140, height: 'auto', float: 'right'}} alt=""/>

          <DatePicker
            defaultValue={moment(this.state.date, dateFormat)}
            onChange={this.onDateChange}
            style={{ marginLeft: 20, display: 'none' }}
          />

          <Button
            onClick={this.onSearch}
            style={{ marginLeft: 20, display: 'none' }}
            icon="search">
            Search
          </Button>
        </div>

        <div className="wrappedMap">
          <GoogleMap googleMapData={this.state.googleMapData} key={this.state.googleMapData.random} />
        </div>
      </div>
    )
  }

  onDateChange = (value) => {
    const date = value.format(dateFormat);
    this.setState({
      date: date
    })
  }

  onSearch = () => {
    this.getGpsHistory();
  }
  
  formateData = (data) => {
    data["random"] = Math.random();
    const result = data.map(item => {
      return {
        Lat: item.Lat,
        Lng: item.Lng,
        Plate: item.Plate,
        Time: moment.utc(item.Time, timeFormat).local().format(timeFormat)
      }
    });
    data["GPS"] = result

    return data 
  }
  
  getGpsHistory = () => {
    const zeroDate = moment(this.state.date).format(zeroFormat);
    const zeroUTCDate = moment(zeroDate).utc().format(dateFormat);

    let submitValue = {
      plate: this.state.plate,
      Date: zeroUTCDate
    };
    httpGet("/api/v1/instance/get_vehicles_current_gps")
      .then(res => {
        return res.json();
      })
      .then(data => {
        if (data.code === 0) {

          //formate data
          const formateData = this.formateData(data.data)

          this.setState({
            googleMapData: formateData,
          })
        } else {
          message.error('Interface error: get_gps_history2');
        }
      })
      .catch(err => {
        console.log(err)
        message.error('Interface error: get_gps_history1');
      }
      )
  }
}
export default Map;