import React, { Component } from 'react'
import {Switch, Route, HashRouter} from 'react-router-dom'
import Login from 'components/Login/Login'
import AllMap from 'components/AllMap'
import AllMap2 from 'components/AllMap2'
import AllMap3 from 'components/AllMap3'
import SubRouter from 'components/Router/SubRouter'

import { ConfigProvider } from 'antd';
import frFR from 'antd/es/locale/fr_FR';

class Routers extends Component{
    render(){
        return (
          <ConfigProvider locale={frFR}>
            <HashRouter>
                <Switch>
                    <Route exact path="/login" component={ Login } />
                    <Route exact path="/allmap" component={ AllMap } />
                    <Route exact path="/allmap2" component={ AllMap2 } />
                    <Route exact path="/allmap3" component={ AllMap3 } />
                    <Route path="/" component={SubRouter}  />
                </Switch>
            </HashRouter>
          </ConfigProvider>
        );
    }
}

export default Routers